import React from "react";
import { graphql } from "gatsby";
import { Page } from "~/components/ui";
import { markdownVars } from "~/utils/helpers";


export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        author
        company
        description
        title
        email
        address
        url
      }
    }
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
      fields {
        slug
        type
      }
    }
  }
`

const PageTemplate = ({ data }) => {
  const { markdownRemark, site } = data;
  const { frontmatter, html } = markdownRemark;

  const seo = {
    title: frontmatter.title,
    description: site.siteMetadata.description
  };

  return (
    <Page {...seo}>
      <article className="prose mb-12">
        <h1>
          <span className="block">{frontmatter.title}</span>
          {frontmatter.date && (
            <small className="block text-lg text-gray-600 my-2">
              {frontmatter.date}
            </small>
          )}
        </h1>

        <div
          dangerouslySetInnerHTML={{
            __html: markdownVars(html, {
              author: site.siteMetadata.author,
              company: site.siteMetadata.company,
              description: site.siteMetadata.description,
              title: site.siteMetadata.title,
              domain: site.siteMetadata.url.replace("https://", "").replace("www.", ""),
              email: site.siteMetadata.email,
              address: site.siteMetadata.address,
              url: site.siteMetadata.url
            })
          }}
        />
      </article>
    </Page>
  )
}

export default PageTemplate;